import { CACHE_KEYS } from 'constants/cache';
import { APP_ROUTES } from 'constants/routes';

import { useState, useEffect, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { favoriteApi } from 'api_entities/favorites';
import { FavoriteCategories, FavoriteItem } from 'api_entities/favorites/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useProfile } from './useProfile';

export const useFavorite = (
  category: FavoriteCategories,
  id: string
): [boolean, (e: MouseEvent<HTMLButtonElement>) => void] => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { data: profileData } = useProfile();

  const favoriteCardsCacheData = queryClient.getQueryData<Array<FavoriteItem>>(
    CACHE_KEYS.FAVORITES
  );
  // updating component on app mount for right favorite state
  useQuery(CACHE_KEYS.FAVORITES, favoriteApi.getFavorites, {
    enabled: !favoriteCardsCacheData && !!profileData?.objectID,
  });
  const favoriteCardItemById = favoriteCardsCacheData?.find(
    (item) => item.category === category && item.entityId === id
  );

  const [isFavoriteButtonStatus, setFavoriteButtonStatus] = useState<boolean>(
    Boolean(favoriteCardItemById)
  );
  useEffect(() => {
    setFavoriteButtonStatus(Boolean(favoriteCardItemById));
  }, [favoriteCardItemById]);

  const { mutate: addToFavorite } = useMutation(
    () => favoriteApi.addToFavorite({ category, entityId: id }),
    {
      onSuccess: (data) => {
        if (favoriteCardsCacheData) {
          queryClient.setQueryData([CACHE_KEYS.FAVORITES, id], [...favoriteCardsCacheData, data]);
        }
      },
    }
  );
  const { mutate: deleteFromFavorite } = useMutation(
    () => favoriteApi.deleteFromFavorite(favoriteCardItemById?.entityId || ''),
    {
      onSuccess: () => {
        if (favoriteCardsCacheData) {
          queryClient.setQueryData(
            [CACHE_KEYS.FAVORITES],
            [
              ...favoriteCardsCacheData.filter((item) => item.category !== category),
              ...favoriteCardsCacheData.filter(
                (item) => item.category === category && item.entityId !== id
              ),
            ]
          );
        }
      },
    }
  );

  const handleFavoriteButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!profileData?.objectID) {
      router
        .push({
          pathname: APP_ROUTES.LOGIN,
          query: {
            from: router.asPath,
            type: 'favorite',
          },
        })
        .then(() => window.scrollTo(0, 0));
      return;
    }
    setFavoriteButtonStatus((prevState) => !prevState);
    favoriteCardItemById ? deleteFromFavorite() : addToFavorite();
  };
  return [isFavoriteButtonStatus, handleFavoriteButton];
};

export const useFavoriteList = () => {
  const { data: profileData } = useProfile();

  return useQuery(CACHE_KEYS.FAVORITES, favoriteApi.getFavorites, {
    enabled: !!profileData?.objectID,
  });
};
