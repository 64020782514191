import { device } from 'constants/device';

import styled from 'styled-components';

export const Banner = styled.section<{ photoURL: string; width?: string; isShortBanner?: boolean }>`
  overflow: hidden;
  background-size: cover;
  background-color: ${({ theme }) => theme.brand.orange};
  border-radius: 18px;
  width: ${({ width }) => (width ? width : '100%')};
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 48px;
  padding-left: 40px;
  padding-bottom: 24px;
  @media ${device.laptopL} {
    width: 100%;
  }
  @media ${device.tablet} {
    min-height: 165px;
    height: unset;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 12px;
    width: 100%;
  }
`;

export const BannerPhotoWrapper = styled.div<{ photoURL?: string; isShortBanner?: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: ${({ isShortBanner }) => (isShortBanner ? '51.12%' : '43.38%')};
  margin: auto;
  background-image: ${({ photoURL }) => `url(${photoURL})`};
  background-size: cover;
  z-index: 0;
  background-position: right;
  @media ${device.tablet} {
    ${({ isShortBanner }) =>
      isShortBanner &&
      `
     left: 65.27%;
    `}
  }
`;

export const BannerGradientWrapper = styled.div<{ isShortBanner?: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0px;
  margin: auto;
  z-index: 1;
  background: ${({ isShortBanner }) =>
    isShortBanner
      ? 'linear-gradient(90deg, #EF7D00 0%,#EF7D00 51.12%, rgba(239, 125, 0, 0) 72%)'
      : 'linear-gradient(90deg, #EF7D00 0%,#EF7D00 43.38%, rgba(239, 125, 0, 0) 68.29%)'};

  @media ${device.tablet} {
    ${({ isShortBanner }) =>
      isShortBanner &&
      `
     background: linear-gradient(90deg, #EF7D00 0%,#EF7D00 65.27%, rgba(239, 125, 0, 0) 82.28%);
    `};
  }
`;

export const BannerDescription = styled.span<{lineCount?: number }>`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  margin-top: 16px;
  color: ${({ theme }) => theme.system.light};
  position: relative;
  z-index: 2;
  display: -webkit-box;
 
  line-clamp:  ${({ lineCount }) => lineCount || 3 };
  -webkit-line-clamp: ${({ lineCount }) => lineCount || 3 };
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${device.tablet} {
    font-size: 1.2rem;
    font-weight: normal;
  }
`;

export const TitleAndDescriptionContainer = styled.div<{withDescription?: boolean}>`
  color: ${({ theme }) => theme.system.light};
  width: 500px;
  position: relative;
  z-index: 2;
  @media ${device.laptopL} {
    width: 420px;
  }
  @media ${device.tablet} {
    width: 70%;
    height: unset;
  }
  & > h1 {
    margin-bottom: 0;
    margin-top: 0;
    display: -webkit-box;
    line-clamp:  ${({ withDescription }) => (withDescription ? 3 : 4)};
    -webkit-line-clamp: ${({ withDescription }) => (withDescription ? 3 : 4)};
    -webkit-box-orient: vertical;
    overflow: hidden;

    text-overflow: ellipsis;
    @media ${device.tablet} {
      margin-bottom: 8px;
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
  }
`;

export const InfoBlocksContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  & > div:not(:first-child) {
    margin-left: 12px;
  }
  @media ${device.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

export const BadgeContainer = styled.div`
  background: ${({ theme }) => theme.brand.blue};
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 140%;
  color: ${({ theme }) => theme.system.light};

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  @media ${device.tablet} {
    padding: 7px 12px;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;

    border-radius: 6px;
  }
  @media ${device.mobileL} {
    order: 2;
  }
`;

export const BadgeContainerRating = styled(BadgeContainer)`
  & > div {
    display: flex;
  }
  @media ${device.tablet} {
    border-radius: 6px;
  }
  @media ${device.mobileL} {
    margin-right: 50%;
    margin-bottom: 8px;
    margin-left: 0 !important;
    order: 1;
  }
`;

export const FavoriteButtonWrapper = styled.div<{ isFavorite?: boolean }>`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: ${({ theme }) => theme.brand.blue};
  display: inline-flex;
  justify-content: center;
  & > button {
    padding: 0;
    width: 100%;
    svg {
      fill: ${({ isFavorite, theme }) => (isFavorite ? theme.system.red : 'none')};
      stroke: ${({ isFavorite, theme }) => (isFavorite ? theme.system.red : theme.system.white)};
      @media ${device.tablet} {
        width: 12px;
        height: 15px;
      }
    }
  }
  @media ${device.tablet} {
    width: 26px;
    height: 26px;
    border-radius: 5px;
    margin-left: 8px !important;
  }
  @media ${device.mobileL} {
    order: 3;
  }
`;

export const StudyMarks = styled.div`
  height: 28px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
`;

export const UniversitiesMark = styled.div``;

export const HBOsMark = styled.span<{ isUniversities: boolean }>`
  padding-right: ${({ isUniversities }) => (isUniversities ? '6px' : '0')};
  margin-right: ${({ isUniversities }) => (isUniversities ? '6px' : '0')};
  border-right: ${({ isUniversities, theme }) =>
    isUniversities ? `2px solid ${theme.system.light}` : '0'};
`;

export const BannerPattern = styled.div<{ isShort?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  & > svg {
    width: ${({ isShort }) => (isShort ? '350px' : '700px')};
    border-radius: 0px 18px 18px 0px;
    @media ${device.laptopL} {
      width: ${({ isShort }) => (isShort ? '300px' : '500px')};
    }
    @media ${device.laptop} {
      width: ${({ isShort }) => (isShort ? '250px' : '400px')};
    }
    @media (max-width: 950px) {
      width: 300px;
    }
    @media (max-width: 830px) {
      width: 200px;
    }
    @media ${device.tablet} {
      width: 90px;
    }
  }
`;

export const VerifiedLabel = styled.div`
  margin-top: 12px;
  display: flex;
  color: ${({ theme }) => theme.system.light};
  align-items: center;
  span {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.4;
    margin-left: 7px;
  }
  @media ${device.tablet} {
    margin-top: 9px;
    span {
      font-size: 1rem;
      margin-left: 6px;
    }
  }
`;
export const VerifiedIcon = styled.div`
  width: 28px;
  @media ${device.tablet} {
    width: 16px;
  }
  svg {
    width: 100%;
    height: auto;
  }
`;
export const BottomBadges_BannerDivider = styled.span`
  margin-left: 10px;
  margin-right: 12px;
  @media ${device.tablet} {
    margin-left: 6px;
    margin-right: 6px;
  }
`;

export const BottomBadges_IconWrapper = styled.div`
  width: 16px;

  display: flex;
  @media ${device.tablet} {
    width: 12px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;
