import { API_URL } from 'constants/routes';

import { fetcher } from 'api_entities/fetch';

import type { Payload, FavoriteItem } from './types';

export const favoriteApi = {
  getFavorites: async function () {
    try {
      const { data } = await fetcher.get<Array<FavoriteItem>>(`${API_URL.FAVORITE}`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  addToFavorite: async function (payload: Payload) {
    try {
      const { data } = await fetcher.post<FavoriteItem>(`${API_URL.FAVORITE}`, payload);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  deleteFromFavorite: async function (id: string) {
    try {
      const { data } = await fetcher.delete<{ result: boolean }>(`${API_URL.FAVORITE}/${id}`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
