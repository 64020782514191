import { size } from 'constants/device';

import React from 'react';
import Image from 'next/image';
import { Button, Icons, Headings, RatingStars } from '@studenten/ui-components';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import patternSearchBanner from '../../../../public/assets/patterns/search-banner.svg';
import patternSearchBannerTablet from '../../../../public/assets/patterns/tablet-home-banner.svg';
import patternSearchBannerMobile from '../../../../public/assets/patterns/mobile-search-banner.svg';

import {
  Banner,
  BannerPattern,
  BannerDescription,
  TitleAndDescriptionContainer,
  BadgeContainer,
  BadgeContainerRating,
  InfoBlocksContainer,
  FavoriteButtonWrapper,
  BannerPhotoWrapper,
  BannerGradientWrapper,
  VerifiedLabel,
  VerifiedIcon,
} from './PageBanner.styled';
import { Props } from './types';
import { parseImageUrl } from '../../../../utils/helpers';

export const PageBanner = ({
  title,
  photoURL,
  description,
  withFavoriteButton,
  isFavorite,
  onFavoriteClick,
  bottomBadgesChildren,
  isShortBanner,
  rating,
  isVerified,
  lineCount,
}: Props) => {
  const { width } = useWindowSize();
  const renderBannerPattern = (): JSX.Element | null => {
    if (width) {
      if (width >= size.laptop) {
        return <Image src={patternSearchBanner} alt="" />;
      }
      if (width >= size.tablet) {
        return <Image src={patternSearchBannerTablet} alt="" />;
      }
      return <Image src={patternSearchBannerMobile} alt="" />;
    }
    return null;
  };

  const photoUrlSrc = parseImageUrl(photoURL).src;

  return (
    <Banner
      photoURL={photoUrlSrc || ''}
      isShortBanner={isShortBanner}
      width={isShortBanner ? 'calc(100% - 420px)' : ''}
    >
      {photoUrlSrc && <BannerPhotoWrapper isShortBanner={isShortBanner} photoURL={photoUrlSrc} />}
      {photoUrlSrc && <BannerGradientWrapper isShortBanner={isShortBanner} />}
      <TitleAndDescriptionContainer withDescription={Boolean(description)}>
        {title && <Headings.H1 dangerouslySetInnerHTML={{ __html: title }}></Headings.H1>}
        {isVerified && (
          <VerifiedLabel>
            <VerifiedIcon>
              <Icons.Verified />
            </VerifiedIcon>
            <span>Verified by Studenten.nl</span>
          </VerifiedLabel>
        )}

        {description && (
          <BannerDescription
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            lineCount={lineCount}
          />
        )}
      </TitleAndDescriptionContainer>
      <InfoBlocksContainer>
        {React.Children.map(bottomBadgesChildren, (child) => (
          <BadgeContainer>{React.cloneElement(child as React.ReactElement)}</BadgeContainer>
        ))}
        {rating !== undefined && (
          <BadgeContainerRating>
            <RatingStars fillEmpty="#414B7A" readonly initialRating={Number(rating)} />
          </BadgeContainerRating>
        )}
        {withFavoriteButton && (
          <FavoriteButtonWrapper isFavorite={isFavorite}>
            <Button variant="primary" onClick={onFavoriteClick}>
              <Icons.Bookmark />
            </Button>
          </FavoriteButtonWrapper>
        )}
      </InfoBlocksContainer>
      {!photoUrlSrc && <BannerPattern isShort={isShortBanner}>{renderBannerPattern()}</BannerPattern>}
    </Banner>
  );
};
